import BaseModel from "./BaseModels/BaseModel";
import { AttachContactRequest } from "./Contact";
import { CPE } from "./CPE";
import { DNSSuffix } from "./DNSSuffix";
import { RouterTypeEnum } from "./enums/RouterTypeEnum";
import HostingsiteBase from "./Hostingsite";
import { Subnet } from "./Subnet";
import { Role, UserResponse } from "./User";

export class SimpleBaseOrganisation extends BaseModel {
  name?: string
}

export default class BaseOrganisation extends SimpleBaseOrganisation {
  fallback_email?: string
  description?: string
  general_contact_information?: string
  service_desk_email?: string
  service_desk_telephone_number?: string
  hotline_telephone_number?: string
}

export class OrganisationResponse extends BaseOrganisation {
  service_monitoring_email?: string
  description?: string
  bgp_activated?: boolean
}

export class DetailedOrganisationResponse extends OrganisationResponse {
  services_count?: number
  clients_count?: number
  agreements_count?: number
  users_count?: number
}

export class CreateOrganisationRequest extends BaseOrganisation {
  contacts: AttachContactRequest[] = []
  hosting_sites: CreateOrganisationHostingSiteRequest[] = []
  subnets: OrganisationHostingSiteSubnet[] = []
  routers: OrganisationRouter[] = []
  dnsSuffixes: OrganisationDNSSuffix[] = []
  cpes: OrganisationCPE[] = []
}

export class OrganisationServiceMonitorering extends BaseModel {
  email?: string
}


export class OrganisationRouter extends BaseModel {
  type?: RouterTypeEnum
  name?: string
  ip_address?: string
  username?: string
  password?: string
  comment?: string
}


export class OrganisationDNSSuffix extends BaseModel {
  dns_suffix?: string
  hosting_site?: HostingsiteBase
}

export class OrganisationCPE extends CPE {
  hosting_site?: HostingsiteBase
}

export class OrganisationSmokeping extends BaseModel {
  ip_address?: string
  description?: string

  vpn_peer?: boolean
  icmp_ping?: boolean
  tcp_ping?: boolean
  tcp_portnr?: number
  http_ping?: boolean
  http_url?: number
}

export class CreateOrganisationAuthorization extends BaseModel {
  role?: Role
  parentUser?: UserResponse
  organisation?: BaseOrganisation
}

export class OrganisationHostingSiteSubnet extends BaseModel {
  subnet?: string
  hosting_site?: HostingsiteBase
}

export class CreateOrganisationHostingSiteRequest extends BaseModel {
  name?: string
  address?: string
  subnets: Subnet[] = []
  dns_suffixes: DNSSuffix[] = []
  cpes: CPE[] = []
}